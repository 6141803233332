import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';

import { getCookie } from './helpers/cookies.js';
import { cookieName, redirectUrl } from './config';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

class App extends Component {

  constructor(props) {
    super();
    this.state = { renderApp: true };
    // Redirect to https if not
    let currentLocation = window.location.href;
    if (
      !currentLocation.includes('localhost') &&
      !currentLocation.includes('127.0.0.1') &&
      currentLocation.includes('http://')
    ) {
      currentLocation = currentLocation.replace('http://', 'https://');
      this.state = { renderApp: false };
      window.location.replace(currentLocation);
    } else {
      // Checking for authentication cookie
      let userCookie = getCookie(cookieName);
      if (process.env.NODE_ENV !== 'development' && !userCookie) {
        this.state = { renderApp: false };
        window.location.assign(redirectUrl);
      }
    }
  }

  render() {
    if (this.state.renderApp) {
      return (
        <HashRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route exact path="/register" name="Register Page" component={Register} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </HashRouter>
      );
    } else {
      return "Redirecting...";
    }
  }

}

export default App;